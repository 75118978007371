import TicketModel, { ITicketModelDTO } from '@common/models/TicketModel';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TicketViewResource } from './api/TicketViewResource';
import { useIntl } from 'react-intl';
import {
  StyledCircleOrange,
  StyledCircleSolidWallOrange,
  StyledPlusOrange,
  StyledPlusPrimary,
  StyledTicketColumnContent,
  StyledTicketColumnLeft,
  StyledTicketColumnRight
} from '@common/components/TicketLayout';
import ReCaptchaInfo from '@common/components/ReCaptchaInfo';
import CaptchaContext from '@common/contexts/Captcha';
import Title from '@common/components/Title';
import PageLoader from '@common/components/PageLoader/PageLoader';
import TicketMessage from './components/TicketMessage/TicketMessage';
import TicketMessageModel from '@common/models/TicketMessage';
import { Button, Form, Modal, Tooltip } from 'antd';
import TicketForm from '@routes/Ticket/routes/TicketCreate/components/TicketForm';
import ActionButton from '@common/components/ActionButton';
import { StyledActionButtonWrapper } from '@common/components/ActionButton/styled';
import FileUpload from '@common/components/FileUpload';
import FormTextArea from '@common/components/FormTextArea';
import { UploadContext } from '@common/contexts/Upload/UploadContext';
import { Rule } from 'antd/es/form';
import Icon from '@common/components/Icon';
import { faMemoCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import TicketFormInfo from './components/TicketFormInfo';
import TicketClose from './components/TicketClose/TicketClose';
import Logo from '@common/components/Logo';
import Empty from '@common/components/Empty';
import { StyledTicketView, StyledTicketHeader, StyledTicketInfoWrapper, StyledGoToListButton } from './styled';
import { IInstanceParam, IShortCodeParam } from '@common/interfaces/UrlParams';
import { buildRouteUrl } from '@common/helpers/OtherHelper';
import { NavigatePaths } from '@common/constants/Paths';
import { useEffectOnce } from '@common/helpers/ActionHelper';
import useAuthHelper from '@common/hooks/useAuthHelper';

const TicketView: React.FC = () => {
  const intl = useIntl();

  const { instanceSlug, shortCode } = useParams<{
    instanceSlug: string;
    shortCode: string;
  }>() as IInstanceParam & IShortCodeParam;

  const { getToken, authorize } = useAuthHelper();
  const [isFormInfoShow, setFormInfoShow] = useState<boolean>(false);
  const [ticket, setTicket] = useState<ITicketModelDTO | null>(null);
  const [ticketMessages, setTicketMessages] = useState<TicketMessageModel[]>([]);
  const [isAppReady, setAppReady] = useState(false);
  const [isSubmitting, setSubbmitting] = useState(false);
  const [form] = Form.useForm();

  const { renderInfo, isReady: isCaptchaReady, executeCaptcha } = useContext(CaptchaContext);
  const { fileList, updateFileList, handleUpload } = useContext(UploadContext);

  const rules: Rule[] = [
    { required: true, message: intl.formatMessage({ id: 'form.required.message' }) },
    {
      max: 200,
      message: intl.formatMessage({ id: 'form.maxLength.message' })
    }
  ];
  useEffectOnce(() => {
    const loadData = async () => {
      try {
        const authToken = getToken();
        const response = await TicketViewResource.get(instanceSlug, shortCode, authToken);
        if (!response) {
          authorize(instanceSlug);
        }

        setTicket(TicketModel.build(response.data.ticket));
        setTicketMessages(TicketMessageModel.load(response.data.ticketMessages));
      } catch (err: any) {
        setAppReady(true);
      } finally {
        setAppReady(true);
      }
    };
    loadData();
  });

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        setSubbmitting(true);
        executeCaptcha(async (token) => {
          const authToken = getToken();
          if (!instanceSlug || !ticket || !authToken) {
            return;
          }
          const response = await TicketViewResource.create(
            instanceSlug,
            ticket.shortCode,
            { messageContent: values.content },
            authToken,
            token
          );
          if (!response.data) {
            setSubbmitting(false);
          }
          const newMessage = TicketMessageModel.build(response.data);

          const responseUpload = await handleUpload(
            `/ticket/${instanceSlug}/${ticket.shortCode}/ticket-message/${newMessage.id}/ticket-file/`
          );
          newMessage.ticketFiles = responseUpload.filter((file) => file.status === 201).map((file) => file.data);
          form.resetFields();
          updateFileList([]);
          setSubbmitting(false);
          setTicketMessages([newMessage, ...ticketMessages]);
        });
      } catch (err) {
        setSubbmitting(false);
      }
    },
    [ticket, fileList]
  );

  const showFormInfoModal = () => {
    setFormInfoShow(true);
  };

  const hideFormInfoModal = () => {
    setFormInfoShow(false);
  };

  const renderReCaptcha = useMemo(
    () => <ReCaptchaInfo visible={isCaptchaReady}>{renderInfo()}</ReCaptchaInfo>,
    [isCaptchaReady, renderInfo]
  );

  if (!isAppReady) {
    return <PageLoader />;
  }

  if (!ticket) {
    return null;
  }

  const renderContent = () => {
    return ticket.isOpen ? (
      <>
        <Link
          to={buildRouteUrl(NavigatePaths.TicketList, [
            {
              key: ':instanceSlug',
              value: instanceSlug
            }
          ])}
        >
          <StyledGoToListButton gray>
            <Icon icon={faArrowLeft} />
            {intl.formatMessage({ id: 'ticket.myTickets' })}
          </StyledGoToListButton>
        </Link>
        <TicketForm
          disabled={isSubmitting}
          form={form}
          layout="vertical"
          name="ticketForm"
          size="large"
          onFinish={onSubmit}
        >
          <Form.Item
            id="content"
            name="content"
            rules={rules}
            label={intl.formatMessage({ id: 'ticket.view.contentField.label' })}
          >
            <FormTextArea max={200} />
          </Form.Item>

          <FileUpload visible={ticket.allowForAttachments} />

          <StyledActionButtonWrapper>
            <ActionButton type="primary" htmlType="submit" loading={isSubmitting}>
              {intl.formatMessage({
                id: 'form.create.submitMessage'
              })}
            </ActionButton>
          </StyledActionButtonWrapper>
        </TicketForm>
      </>
    ) : ticket.ticketForm ? (
      <TicketClose shortCode={ticket.ticketForm.shortCode} />
    ) : null;
  };

  return (
    <StyledTicketInfoWrapper>
      <StyledTicketView>
        <StyledTicketColumnLeft>
          <StyledPlusOrange />
          <StyledCircleSolidWallOrange />
        </StyledTicketColumnLeft>
        <StyledTicketColumnContent>{renderContent()}</StyledTicketColumnContent>

        <StyledTicketColumnRight>
          <StyledPlusPrimary />
          <StyledCircleOrange />
          <StyledTicketHeader>
            <Title>{ticket.subject}</Title>
            {ticket.ticketForm && (
              <Tooltip title={intl.formatMessage({ id: 'ticket.view.formInfo.tooltip' })}>
                <span onClick={showFormInfoModal}>
                  <Icon icon={faMemoCircleInfo} />
                </span>
              </Tooltip>
            )}
          </StyledTicketHeader>
          {ticketMessages.length ? (
            ticketMessages.map((message, index) => <TicketMessage key={index} message={message} ticket={ticket} />)
          ) : (
            <Empty emptyMessage={intl.formatMessage({ id: 'ticket.view.emptyMessages' })} />
          )}
        </StyledTicketColumnRight>
      </StyledTicketView>
      <Logo />
      {renderReCaptcha}

      <Modal
        open={isFormInfoShow}
        onOk={hideFormInfoModal}
        onCancel={hideFormInfoModal}
        footer={[
          <Button key="ok" onClick={hideFormInfoModal}>
            OK
          </Button>
        ]}
      >
        {ticket.ticketForm ? <TicketFormInfo form={ticket.ticketForm} fields={ticket.submittedForm} /> : null}
      </Modal>
    </StyledTicketInfoWrapper>
  );
};

export default TicketView;

import createTicketVideo from '@assets/create-ticket.mp4';
import createTicketSuccessVideo from '@assets/create-ticket-success.mp4';
import { useParams } from 'react-router-dom';
import { useCallback, useContext, useMemo, useState } from 'react';
import TicketFormModel, { ITicketFormModelDTO } from '@common/models/TicketFormModel';
import { TicketCreateResource } from './api/TicketCreateResource';
import { useEffectOnce } from '@common/helpers/ActionHelper';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import ActionButton from '@common/components/ActionButton';
import { StyledActionButtonWrapper } from '@common/components/ActionButton/styled';
import TicketForm from './components/TicketForm';
import PageVideo from '@common/components/PageVideo';
import Title from '@common/components/Title';
import TicketModel, { ITicketModelDTO } from '@common/models/TicketModel';
import TicketMessageModel, { ITicketMessageModelDTO } from '@common/models/TicketMessage';
import TicketCreateSuccess from './components/TicketCreateSuccess';
import FileUpload from '@common/components/FileUpload';
import { UploadContext } from '@common/contexts/Upload/UploadContext';
import CaptchaContext from '@common/contexts/Captcha';
import ReCaptchaInfo from '@common/components/ReCaptchaInfo';
import {
  StyledCircleOrange,
  StyledCircleSolidWallOrange,
  StyledPlusOrange,
  StyledPlusPrimary,
  StyledTicketColumnContent,
  StyledTicketColumnLeft,
  StyledTicketColumnRight,
  StyledTicketWrapper
} from '@common/components/TicketLayout';
import PageLoader from '@common/components/PageLoader';
import Text from '@common/components/Text';
import Logo from '@common/components/Logo/Logo';
import RoundedBox from '@common/components/RoundedBox/RoundedBox';
import { StyledTicketCreate } from './styled';

const TicketCreate: React.FC = () => {
  const { instanceSlug, shortCode } = useParams<{ instanceSlug: string; shortCode: string }>();

  const intl = useIntl();
  const [form] = Form.useForm();
  const [isAppReady, setAppReady] = useState<boolean>(false);

  const { fileList, handleUpload, updateFileList } = useContext(UploadContext);

  const { renderInfo, isReady: isCaptchaReady, executeCaptcha } = useContext(CaptchaContext);

  const [isSubmitting, setSubbmitting] = useState(false);
  const [externalToken, setExternalToken] = useState<string>();
  const [ticketForm, setTicketForm] = useState<ITicketFormModelDTO | null>(null);
  const [ticket, setTicket] = useState<ITicketModelDTO | null>(null);
  const [ticketMessage, setTicketMessage] = useState<ITicketMessageModelDTO | null>(null);

  useEffectOnce(() => {
    const loadData = async () => {
      try {
        if (instanceSlug && shortCode) {
          const response = await TicketCreateResource.get(instanceSlug, shortCode);
          setTicketForm(TicketFormModel.build(response.data));
        }
      } catch (err) {
        setTicketForm(null);
      } finally {
        setAppReady(true);
      }
    };
    loadData();
  });

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        setSubbmitting(true);
        executeCaptcha(async (token) => {
          if (!instanceSlug || !shortCode) {
            return;
          }
          const response = await TicketCreateResource.create(
            instanceSlug,
            shortCode,
            TicketFormModel.serialize(values, ticketForm?.fields),
            token
          );

          const ticketModel: TicketModel = TicketModel.build(response.data.ticket);
          const ticketMessage: TicketMessageModel = TicketMessageModel.build(response.data.ticketMessages[0]);

          await handleUpload(
            `/ticket/${instanceSlug}/${ticketModel.shortCode}/ticket-message/${ticketMessage.id}/ticket-file/`
          );

          setSubbmitting(false);
          updateFileList([]);
          setTicket(ticketModel);
          setTicketMessage(ticketMessage);
        });
      } catch (err) {
        setSubbmitting(false);
        updateFileList([]);
        setTicket(null);
        setTicketMessage(null);
      } finally {
        setSubbmitting(false);
      }
    },
    [externalToken, ticketForm, ticket, fileList]
  );

  const resetForm = useCallback(() => {
    form.resetFields();
    setExternalToken('');
    updateFileList([]);
    setTicket(null);
    setTicketMessage(null);
  }, [ticket, ticketMessage]);

  const renderFormContent = () => {
    if (ticket && ticketMessage) {
      return <TicketCreateSuccess reset={resetForm} ticket={ticket} />;
    } else if (ticketForm) {
      return (
        <>
          <RoundedBox>{intl.formatMessage({ id: 'welcomeInfo.text' })}</RoundedBox>
          <Title level={1}>{ticketForm?.name}</Title>
          <Text>{intl.formatMessage({ id: 'form.create.text' })}</Text>

          <TicketForm
            fields={ticketForm.fields}
            disabled={isSubmitting}
            form={form}
            layout="vertical"
            name="ticketForm"
            size="large"
            onFinish={onSubmit}
          >
            <FileUpload visible={ticketForm.allowForAttachmentsOnCreate} />
            <StyledActionButtonWrapper>
              <ActionButton
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                text={intl.formatMessage({
                  id: 'form.create.submit'
                })}
              />
            </StyledActionButtonWrapper>
          </TicketForm>
        </>
      );
    } else {
      return null;
    }
  };

  const renderReCaptcha = useMemo(
    () => <ReCaptchaInfo visible={isCaptchaReady}>{renderInfo()}</ReCaptchaInfo>,
    [isCaptchaReady, renderInfo]
  );

  if (!isAppReady) {
    return <PageLoader />;
  }

  if (!ticketForm) {
    return null;
  }

  return (
    <StyledTicketWrapper>
      <StyledTicketCreate>
        <StyledTicketColumnLeft>
          <StyledPlusOrange />
          <StyledCircleSolidWallOrange />
        </StyledTicketColumnLeft>
        <StyledTicketColumnContent>{renderFormContent()}</StyledTicketColumnContent>

        <StyledTicketColumnRight>
          <StyledPlusPrimary />
          <StyledCircleOrange />
          <PageVideo video={ticket && ticketMessage ? createTicketSuccessVideo : createTicketVideo} />
        </StyledTicketColumnRight>
      </StyledTicketCreate>
      <Logo />
      {renderReCaptcha}
    </StyledTicketWrapper>
  );
};

export default TicketCreate;
